export default {
    id: {
        id: 'store_id',
        title: 'Идентификатор магазина *',
        type: 'text',
        validation: {
            required: true,
        },
        valid: false,
        touched: false
    },
    name: {
        id: 'store_name',
        title: 'Наименование магазина *',
        type: 'text',
        validation: {
            required: true,
        },
        valid: false,
        touched: false
    },
    email: {
        id: 'email',
        title: 'E-Mail тех.поддержки',
        type: 'email',
        valid: true,
        touched: false
    },
    skype: {
        id: 'skype',
        title: 'Skype',
        type: 'text',
        valid: true,
        touched: false
    },
    phone: {
        id: 'phone',
        title: 'Телефон',
        type: 'text',
        valid: true,
        touched: false
    },
    activityKind: {
        id: 'activity_kind',
        title: 'Вид деятельности магазина',
        type: 'text',
        valid: true,
        touched: false
    },
    resultUrlMethod: {
        id: 'result_url_method',
        title: 'Result URL метод',
        type: 'radio',
        value: 'post',
        valid: true,
        values: [
            {
                label: 'POST',
                value: 'post',
                defaultChecked: true
            },
            {
                label: 'GET',
                value: 'get',
            },
            {
                label: 'E-Mail',
                value: 'email',
            }
        ],
    },
    resultUrl: {
        id: 'result_url',
        title: 'Result URL',
        type: 'text',
        valid: true,
        touched: false
    },
};
