import React from 'react';
import qs from 'qs';

import axios from '../../shared/network/axios';

import withSnackbarHandler from '../../hoc/withSnackbarHandler';
import Common from '../../components/Page/Common/Common';
import Input from '../../components/UI/Input';
import Button from '../../components/UI/Button';

import styles from './ChangePassword.module.sass';
import token from '../../shared/token';
import Form from '../../components/Page/Form';

const ChangePassword = ({ history, snackbarShowHandler, ...rest }) => {
    let newPassword = React.createRef();
    let newPasswordRepeat = React.createRef();

    let [error, setError] = React.useState(false);

    let submit = e => {
        if (e) e.preventDefault();

        if (
            newPassword.current.value !==
            newPasswordRepeat.current.value
        ) {
            setError(true);
            snackbarShowHandler('Пароли должны совпадать');
            return;
        }

        if (newPassword.current.value.length < 5) {
            setError(true);
            snackbarShowHandler('Пароль должен быть длиной больше 4 символов');
            return;
        }

        axios({
            method: 'post',
            url: 'settings/password',
            headers: {
                'content-type':
                    'application/x-www-form-urlencoded;charset=utf-8'
            },
            data: qs.stringify({
                password: newPassword.current.value
            })
        })
            .then(
                response => {
                    token.clear();
                    history.push('/auth');
                },
                error => {
                    setError(true);
                    snackbarShowHandler('Ошибка смены пароля');
                }
            )
            .catch(error => {
                console.log(error);
            });
    };

    return (
        <Common title={'Новый пароль'} returnUrl={'/'}>
            <Form>
                <form onSubmit={submit}>
                    <Input
                        type='password'
                        ref={newPassword}
                        hasErrors={error}
                        label={'Введите новый пароль'}
                    />
                    <Input
                        type='password'
                        ref={newPasswordRepeat}
                        hasErrors={error}
                        label={'Повторите новый пароль'}
                    />
                    <Button
                        type={'submit'}
                        text={'Сохранить'}
                        bordered
                        arrowed
                        className={styles.submit}
                    />
                </form>
            </Form>
        </Common>
    );
};

export default withSnackbarHandler(ChangePassword);
