import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import PrivateRoute from './shared/PrivateRoute';

import Auth from './containers/Auth';
import Main from './containers/Main';
import NewPayment from './containers/Payment/NewPayment';
import PaymentCheck from './containers/Payment/PaymentCheck';
import ChangePassword from './containers/ChangePassword';
import PaymentRegistry from './containers/Payment/PaymentList/Registry';
import Keys from './containers/Keys';
import StoreList from './containers/Store/List';
import StoreAdd from './containers/Store/Add';
import Page404 from './components/Page/404';

class App extends Component {
    render() {
        return (
            <Switch>
                <Route path='/auth' exact component={Auth} />
                <PrivateRoute path='/' exact component={Main} />
                <PrivateRoute path='/add' exact component={NewPayment} />
                <PrivateRoute
                    path='/code/:code'
                    exact
                    component={PaymentCheck}
                />
                <PrivateRoute
                    path='/password'
                    exact
                    component={ChangePassword}
                />
                <PrivateRoute path='/payments' exact component={PaymentRegistry} />
                <PrivateRoute path='/keys' exact component={Keys} />
                <PrivateRoute path='/stores' exact component={StoreList} />
                <PrivateRoute path='/stores/add' component={StoreAdd} />
                <Route component={Page404} />
            </Switch>
        );
    }
}

export default App;
