import React from 'react';
import Common from '../../../components/Page/Common/Common';
import styles from './PaymentCheck.module.sass';
import done from '../../../assets/lottie/checked-done';
import Lottie from 'lottie-react-web';
import Heading from '../../../components/UI/Heading';
import Button from '../../../components/UI/Button';
import Paragraph from '../../../components/UI/Paragraph';
import axios from '../../../shared/network/axios';
import moment from 'moment';
import withSnackbarHandler from '../../../hoc/withSnackbarHandler';

const PaymentCheck = ({ history, match }) => {
    let back = () => {
        history.push('/');
    };

    let [paid, setPaid] = React.useState(false);
    let [firstLoad, setFirstLoad] = React.useState(true);

    React.useEffect(() => {
        let timer = setInterval(() => {
            axios
                .get('/code', {
                    params: {
                        code: match.params.code
                    }
                })
                .then(
                    response => {
                        if (firstLoad) setFirstLoad(false);

                        if (response.data.paid) {
                            clearInterval(timer);
                            setPaid(response.data.paid);
                        }

                        if (
                            moment().diff(
                                moment(
                                    response.data.expires,
                                    'DD.MM.YYYY HH:mm'
                                )
                            ) > 0
                        ) {
                            back();
                        }
                    },
                    error => {
                        if (error.code === 404) {
                            clearInterval(timer);
                            back();
                        }
                    }
                )
                .catch(err => {
                    console.log(err);
                    back();
                });
        }, 1000);

        return () => {
            if (timer) clearInterval(timer);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <Common returnUrl='/' title={`Код ${match.params.code}`}>
            <div className={styles.content}>
                {!paid && (
                    <>
                        <div className={styles.wirl} />
                        <div className={styles.loadingText}>
                            {firstLoad && (
                                <Paragraph>
                                    Загружаем информацию о платеже
                                </Paragraph>
                            )}
                            {!firstLoad && (
                                <Paragraph>
                                    При оплате клиентом страница сама обновится
                                </Paragraph>
                            )}
                        </div>
                    </>
                )}
                {paid && (
                    <>
                        <div className={styles.done}>
                            <Lottie
                                direction={1}
                                isStopped={false}
                                options={{
                                    animationData: done,
                                    loop: false,
                                    autoplay: true
                                }}
                            />
                        </div>
                        <div className={styles.doneText}>
                            <Heading level={2}>Оплачено</Heading>
                            <Button
                                onClick={back}
                                text={'Вернуться к платежам'}
                                bordered
                                uppercase
                            />
                        </div>
                    </>
                )}
            </div>
        </Common>
    );
};

export default withSnackbarHandler(PaymentCheck);
