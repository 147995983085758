import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import classNames from 'classnames';

import axios from '../../shared/network/axios';
import token from '../../shared/token';

import withSnackbarHandler from '../../hoc/withSnackbarHandler';
import Logo from '../../components/UI/Logo';
import Heading from '../../components/UI/Heading';
import Paragraph from '../../components/UI/Paragraph';
import Input from '../../components/UI/Input';
import Button from '../../components/UI/Button';
import Paper from '../../components/UI/Paper';

import styles from './Auth.module.sass';
import imageLogin from '../../assets/lottie/img-login';
import Lottie from 'lottie-react-web';

class Auth extends Component {
    state = {
        redirect: token.get(),
        loading: false,
        loginErrors: false,
        passwordErrors: false
    };

    submit = event => {
        if (event) {
            event.preventDefault();
        }

        let login = this.refs.login.value;
        let password = this.refs.password.value;

        this.setState({...this.state, loginErrors: login === '', passwordErrors:password === ''});

        if (!this.state.loginErrors && !this.state.passwordErrors && !this.props.loading) {
            this.auth(login, password);
        }
    };

    auth = (username, password) => {
        this.setState({ loading: true });
        axios
            .get('/auth/check', {
                auth: {
                    username: username,
                    password: password
                }
            })
            .then(resp => {
                token.set(username, password);
                localStorage.setItem('store', resp.data.store);
                if (resp.data.store)
                    localStorage.setItem('store_id', username);
                else
                    localStorage.removeItem('store_id');
                this.setState({ redirect: true, loading: false });
            })
            .catch(error => {
                this.setState({ loading: false });
                this.props.snackbarShowHandler(
                    'Ошибка авторизации. Проверьте вводимые данные'
                );
                this.setState({...this.state, loginErrors: true, passwordErrors: true});
            });
    };

    render() {
        if (this.state.redirect) {
            return <Redirect to='/' />;
        }

        return (
            <div className={styles.wrap}>
                <Paper elevation={0} className={styles.inner}>
                    <Logo className={styles.logo} postfix='Продавцам' />
                    <div className={styles.img}>
                        <Lottie
                            options={{
                                animationData: imageLogin
                            }}
                        />
                    </div>
                    <div className={styles.fields}>
                        <Heading blue level={2}>
                            Введите данные для входа
                        </Heading>
                        <Paragraph className={styles.wide} blue>
                            Введите логин и пароль администратора, полученные в банке, либо
                            сгенерированные самостоятельно код и пароль магазина
                        </Paragraph>
                        <form onSubmit={this.submit}>
                            <Input
                                className={classNames([styles.field, styles.wide])}
                                disabled={this.state.loading}
                                label='Логин'
                                ref='login'
                                hasErrors={this.state.loginErrors}
                            />
                            <Input
                                className={classNames([styles.field, styles.wide])}
                                disabled={this.state.loading}
                                type='password'
                                label='Пароль'
                                ref='password'
                                hasErrors={this.state.passwordErrors}
                            />
                            <Button
                                onClick={this.submit}
                                className={classNames([styles.to_right, styles.field])}
                                bordered
                                arrowed
                                loading={this.state.loading}
                                text='Войти'
                                type='submit'
                                ref='submit'
                            />
                        </form>
                    </div>
                </Paper>
            </div>
        );
    }
}

export default withSnackbarHandler(Auth);
