import axios from 'axios';
import Token from "../token";

let instance = axios.create({
    baseURL: process.env.REACT_APP_ID_PATH
});

instance.interceptors.request.use(config => {
    let token = Token.get();
    if(token)
        config.headers.Authorization = `Basic ${token}`;
    return config;
});

export default instance