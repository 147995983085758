import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './Backdrop.module.sass';

const Backdrop = React.forwardRef(function Backdrop(props, ref) {
    const { children, show, onClick, className: classNameProp } = props;

    const className = classnames(styles.backdrop, classNameProp);

    return show ? (
        <div ref={ref} className={className} onClick={onClick}>
            {children}
        </div>
    ) : null;
});

Backdrop.propTypes = {
    /**
     * @ignore
     */
    children: PropTypes.element,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * If `true`, show the component.
     */
    show: PropTypes.bool,
    /**
     * @ignore
     */
    onClick: PropTypes.func
};

export default Backdrop;
