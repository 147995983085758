import React from 'react';
import moment from 'moment';

import axios from '../../../../shared/network/axios';
import { prettyDate } from '../../../../shared/helpers';

import Common from '../../../../components/Page/Common';
import StatefulContainer from '../../../../components/UI/StatefulContainer';
import Spinner from '../../../../components/UI/Spinner';
import Paragraph from '../../../../components/UI/Paragraph';
import Button from '../../../../components/UI/Button';
import AlertDialog from '../../../../components/Modal/Dialog/AlertDialog';
import Dialog from '../../../../components/Modal/Dialog';

import { ReactComponent as Check } from '../../../../assets/images/ic-check-green-filled.svg';

import style from './PaymentRegistry.module.sass';

const PaymentRegistry = () => {
    const formatDate = 'DD-MM-YYYY'; // Moment.js format for dd-MM-yyyy
    const formatDateCustom = 'DD.MM.YYYY';

    const [payments, setPayments] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [state, setState] = React.useState('empty');
    const [period, setPeriod] = React.useState(null);

    const isStore = localStorage.getItem('store') === 'false';
    let select;
    const fromDateCustom = React.createRef();
    const toDateCustom = React.createRef();

    const getPayments = () => {
        if (period === null) {
            return;
        }

        const [from, to] = period.split(':');
        setLoading(true);
        axios
            .get(`code?from=${from}&to=${to}`)
            .then(response => {
                const payments = response.data;
                setLoading(false);
                setPayments(payments);
                if (payments === null) {
                    setState('error');
                } else {
                    setState('none');
                }
            })
            .catch(() => {
                setLoading(false);
                setState('error');
            });
    };

    React.useEffect(() => {
        getPayments();
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [period]);

    const showToday = () => {
        const today = moment().format(formatDate);
        setPeriod(`${today}:${today}`);
    };

    const showYesterday = () => {
        const yesterday = moment().subtract(1, 'days').format(formatDate);
        setPeriod(`${yesterday}:${yesterday}`);
    };

    const thisWeek = () => {
        const now = moment().format(formatDate);
        const weekBegin = moment().startOf('week').format(formatDate);
        setPeriod(`${weekBegin}:${now}`);
    };

    const lastWeek = () => {
        const now = moment().format(formatDate);
        const weekAgo = moment().subtract(7, 'days').format(formatDate);
        setPeriod(`${weekAgo}:${now}`);
    };

    const thisMonth = () => {
        const now = moment().format(formatDate);
        const weekBegin = moment().startOf('month').format(formatDate);
        setPeriod(`${weekBegin}:${now}`);
    };

    const lastMonth = () => {
        const now = moment().format(formatDate);
        const weekAgo = moment().subtract(1, 'months').format(formatDate);
        setPeriod(`${weekAgo}:${now}`);
    };

    const customDates = () => {
        if(fromDateCustom.current.state.value && toDateCustom.current.state.value)
            setPeriod(`${
                moment(fromDateCustom.current.state.value, formatDateCustom).format(formatDate)
            }:${
                moment(toDateCustom.current.state.value, formatDateCustom).format(formatDate)
            }`);
    };

    const ownPeriod = () => {
        select.dismiss();
        let ownPeriodDialog = new AlertDialog.Builder()
            .withTitle(<Dialog.Title>Выберите период</Dialog.Title>)
            .withInputs([
                    <Dialog.Input ref={fromDateCustom} type="date" label="Дата с" />,
                    <Dialog.Input ref={toDateCustom} type="date" label="Дата по" />
                    ]
            )
            .withButtons([
                <Dialog.Button key={'choose'} onClick={customDates}>
                    Выбрать
                </Dialog.Button>,
                <Dialog.Button appearance={'error'} key={'close'} onClick={() => ownPeriodDialog.dismiss()}>
                    Закрыть
                </Dialog.Button>
            ])
            .setOutsideNotClosable()
            .build();
        ownPeriodDialog.show();
    };

    const showDialog = () => {
        select = new AlertDialog.Builder()
            .withButtons([
                <Dialog.Button key={'today'} onClick={showToday}>
                    Сегодня
                </Dialog.Button>,
                <Dialog.Button key={'yesterday'} onClick={showYesterday}>
                    Вчера
                </Dialog.Button>,
                <Dialog.Button key={'thisWeek'} onClick={thisWeek}>
                    Эта неделя
                </Dialog.Button>,
                <Dialog.Button key={'lastWeek'} onClick={lastWeek}>
                    Последняя неделя
                </Dialog.Button>,
                <Dialog.Button key={'thisMonth'} onClick={thisMonth}>
                    Этот месяц
                </Dialog.Button>,
                <Dialog.Button key={'lastMonth'} onClick={lastMonth}>
                    Последний месяц
                </Dialog.Button>,
                <Dialog.Button key={'own'} onClick={ownPeriod}>
                    Свой период
                </Dialog.Button>,
            ])
            .build();
        select.show();
    };

    const content = () => {
        if (payments && payments.length > 0) {
            const total = payments.filter((p) => p.paid).reduce((p, c) => p + Number(c.sum), 0);

            let grouped = {};
            // eslint-disable-next-line no-unused-vars
            for (const pay of payments) {
                const date = pay.create.split(' ')[0];
                grouped[date] = grouped[date]
                    ? [...grouped[date], pay]
                    : [pay];
            }

            const dates = Object.keys(grouped).sort((a, b) => {
                const aDate = moment(a, 'DD.MM.YYYY');
                const bDate = moment(b, 'DD.MM.YYYY');
                return bDate.toDate().getTime() - aDate.toDate().getTime();
            });

            const day = (date, operations) => (
                <React.Fragment key={date}>
                    <tr className={style.date} key={date}>
                        <td className={style.date} colSpan={3}>
                            {prettyDate(date)}
                        </td>
                    </tr>
                    {
                        operations.map(p => (
                                <tr key={`${date}${p.invoice_id}`}>
                                    <td>
                                        <span className={style.time}>{p.create.split(' ')[1]}</span>
                                        {
                                            isStore
                                                ? <><span className={style.legend}>{p.store}</span>{p.store_name}</>
                                                : p.code || 'QR'
                                        }
                                    </td>
                                    <td className={style.amount}><b>{Number(p.sum).toFixed(2)}</b> RUP</td>
                                    <td>{p.paid && <Check className={style.done} />}
                                    </td>
                                </tr>
                            )
                        )
                    }
                </React.Fragment>
            );

            return (
                <table className={style.payments}>
                    <thead>
                    <tr>
                        <th>{isStore ? 'Магазин' : 'Код'}</th>
                        <th className={style.amount}>Сумма</th>
                        <th />
                    </tr>
                    </thead>
                    <tbody>
                    {
                        dates.map(d => day(d, grouped[d]))
                    }
                    <tr key={'total'} className={style.total}>
                        <td><b>Итого:</b></td>
                        <td className={style.amount}><b>{Number(total).toFixed(2)}</b> RUP</td>
                        <td />
                    </tr>
                    </tbody>
                </table>
            );
        } else {
            return <Paragraph mode={'body-medium'}>Не найдено оплат за выбранный период</Paragraph>;
        }
    };

    let component = (
        <Common title={'Реестр'} returnUrl={'/'}>
            <StatefulContainer
                state={state}
                empty={{
                    text: 'Выберите интересующий Вас период и мы покажем Вам список оплат',
                    actionText: 'Выбрать',
                    action: showDialog
                }}
                error={{ action: getPayments, actionText: 'Повторить попытку' }}
            >
                <Button text={'‣'} small uppercase className={style.button} onClick={showDialog} />
                {content()}
                <Button text={'Печать'} className={style.buttonPrint} onClick={window.print} />
            </StatefulContainer>
        </Common>
    );

    if (loading) {
        component = <Spinner fixed />;
    }

    return component;
};

export default PaymentRegistry;
