import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import Lottie from 'lottie-react-web';

import token from './../../../shared/token';

import Logo from '../../UI/Logo';
import Paper from '../../UI/Paper';
import AlertDialog from '../../Modal/Dialog/AlertDialog';
import Dialog from '../../Modal/Dialog';

import settingsAnimation from '../../../assets/lottie/settings';

import styles from './Toolbar.module.sass';

const Toolbar = ({ history, ...rest }) => {
    let [settingsIconDirection, toggleSettingsIconDirection] = React.useState(
        1
    );

    const menuShow = () => {
        let dialogButtons = (localStorage.getItem('store') === 'false'
                ? [
                    <Dialog.Button key='stores' onClick={() => {
                        history.push(`/stores`);
                    }}>
                        Управление магазинами
                    </Dialog.Button>,
                    <Dialog.Button
                        key='keys'
                        onClick={() => {
                            history.push(`/keys`);
                        }}
                    >
                        Управление ключами
                    </Dialog.Button>
                ]
                : []
        ).concat([
            <Dialog.Button key='stores' appearance={'primary'} onClick={() => {
                history.push(`/payments`);
            }}>
                Реестр оплат
            </Dialog.Button>,
            <Dialog.Button
                key='password'
                onClick={() => {
                    history.push(`/password`);
                }}
            >
                Смена пароля
            </Dialog.Button>,
            <Dialog.Button
                key='exit'
                appearance={'error'}
                onClick={() => {
                    token.clear();
                    history.push(`/auth`);
                }}
            >
                Выход
            </Dialog.Button>,
            <Dialog.Button key='cancel'>Отмена</Dialog.Button>
        ]);

        new AlertDialog.Builder()
            .withButtons(dialogButtons)
            .build()
            .show();
    };

    return (
        <Paper
            elevation={0}
            component='header'
            square
            className={styles.toolbar}
        >
            <div className={styles.content}>
                <div className={styles.logo}>
                    <NavLink to='/' exact>
                        <Logo />
                    </NavLink>
                </div>

                <div
                    className={styles.icon}
                    onClick={() => {
                        toggleSettingsIconDirection(settingsIconDirection * -1);
                        menuShow();
                    }}
                >
                    <Lottie
                        direction={settingsIconDirection}
                        options={{
                            animationData: settingsAnimation,
                            loop: false
                        }}
                    />
                </div>
            </div>
        </Paper>
    );
};

export default withRouter(Toolbar);
