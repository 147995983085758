import React from 'react';
import { withRouter } from 'react-router-dom';

import useFormFields from '../hooks/useFormFields';
import useDrawForm from '../hooks/useDrawForm';
import axios from '../../../../shared/network/axios';

import Button from '../../../../components/UI/Button';
import Paragraph from '../../../../components/UI/Paragraph';
import AlertDialog from '../../../../components/Modal/Dialog/AlertDialog';
import Dialog from '../../../../components/Modal/Dialog';

import initialForm from './mobileForm';

import style from './Code.module.sass';

const StoreByCode = ({ history, ...rest }) => {

    const [loading, setLoading] = React.useState(false);
    const [fields, inputChangeHandler, isValid] = useFormFields(initialForm);
    const form = useDrawForm(fields, inputChangeHandler);

    const onCreateHandler = () => {
        if (isValid) {
            setLoading(true);
            let store = {};

            // eslint-disable-next-line no-unused-vars
            for (let f of fields) {
                store[f.id] = f.config.value;
            }

            axios
                .post(
                    '/stores',
                    JSON.stringify(store),
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                )
                .then(response => {
                    setLoading(false);
                    new AlertDialog.Builder()
                        .appendButton(<Dialog.Button onClick={e => {
                            history.replace('/stores');
                        }}>ОК</Dialog.Button>)
                        .setOutsideNotClosable()
                        .withTitle(<Dialog.Title>Создание магазина</Dialog.Title>)
                        .withDescription(
                            <Dialog.Description>
                                Секретный код магазина <b id={'store-code'}>{response.data}</b>.
                                Запомните, или запишите его!
                            </Dialog.Description>
                        )
                        .build()
                        .show();
                })
                .catch(error => {
                    setLoading(false);
                    new AlertDialog.Builder()
                        .withTitle(<Dialog.Title>Ошибка</Dialog.Title>)
                        .withDescription(<Dialog.Description>Проверьте заполняемые данные</Dialog.Description>)
                        .build()
                        .show();
                });
        }
    };

    return (
        <>
            <Paragraph mode={'body-medium'}>Добавление формы для оплаты мобильным платежом</Paragraph>
            {form}
            <Button
                disabled={!isValid}
                loading={loading}
                className={style.submit}
                text={'Добавить'}
                onClick={onCreateHandler} />
        </>
    );
};

export default withRouter(StoreByCode);
