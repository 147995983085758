import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react-web';

import Paragraph from '../Paragraph';
import Button from '../Button';

import imageError from '../../../assets/lottie/fetch-data-error';
import imageEmpty from '../../../assets/lottie/img-office';

import style from './StatefulContainer.module.sass';

const StatefulContainer = props => {
    const { children, state = 'none', empty, error } = props;

    const content = (image, lottie, text, action, actionText) => {
        return (
            <div className={style.spacer}>
                <div className={style.img}>
                    {lottie != null ? (
                        <Lottie
                            options={{
                                animationData: lottie
                            }}
                        />
                    ) : (
                        <img src={image} alt={'Изображение'} />
                    )}
                </div>
                {text && <Paragraph mode={'body-medium'}>{text}</Paragraph>}

                {action && (
                    <Button
                        text={actionText || 'Обновить'}
                        uppercase
                        onClick={action}
                    />
                )}
            </div>
        );
    };

    switch (state) {
        case 'empty':
            return content(
                empty.image,
                empty.lottie || imageEmpty,
                empty.text,
                empty.action,
                empty.actionText
            );
        case 'error':
            return content(
                error.image,
                error.lottie || imageError,
                error.text ||
                'О, нет! Кажется возникла ошибка при получении данных с сервера. Попробуйте повторить попытку.',
                error.action,
                error.actionText
            );
        default:
            return children;
    }
};

const stateShape = {
    image: PropTypes.string,
    lottie: PropTypes.object,
    text: PropTypes.string,
    action: PropTypes.func,
    actionText: PropTypes.string
};

StatefulContainer.propTypes = {
    children: PropTypes.any,
    state: PropTypes.oneOf(['none', 'empty', 'error']),
    empty: PropTypes.shape(stateShape),
    error: PropTypes.shape(stateShape),
};

export default StatefulContainer;
