import React, { Component } from 'react';

import Snackbar from '../../components/UI/Snackbar';
import { UUID } from '../../shared/helpers';
import Queue from '../../shared/Queue';

const withSnackbarHandler = WrappedComponent =>
    class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                open: false,
                messageInfo: null
            };
            this.queue = new Queue();
        }

        processQueue = () => {
            if (!this.queue.isEmpty) {
                this.setState({
                    open: true,
                    messageInfo: this.queue.dequeue()
                });
            }
        };

        /**
         * Allow enqueue new message and show it.
         *
         * @param {string} message
         * @param {node} icon
         * @param {node} action
         */
        handleMessage = (message, icon, action, duration) => {
            if (!message) {
                return;
            }

            message = message.trim();

            if (message.length === 0) {
                return;
            }

            this.queue.enqueue({
                message,
                icon,
                action,
                duration,
                key: UUID()
            });

            if (this.state.open) {
                // immediately begin dismissing current message
                // to start showing new one
                this.setState({ open: false });
            } else {
                this.processQueue();
            }
        };

        handleClose = (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }
            this.setState({ open: false });
        };

        handleExited = () => {
            this.processQueue();
        };

        render() {
            return (
                <>
                    <WrappedComponent
                        {...this.props}
                        snackbarShowHandler={this.handleMessage}
                        snackbarCloseHandler={this.handleClose}
                    />
                    <Snackbar
                        key={
                            this.state.messageInfo
                                ? this.state.messageInfo.key
                                : undefined
                        }
                        autoHideDuration={
                            this.state.messageInfo
                                ? this.state.messageInfo.duration
                                : undefined
                        }
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                        open={this.state.open}
                        onClose={this.handleClose}
                        onExited={this.handleExited}
                        icon={
                            this.state.messageInfo
                                ? this.state.messageInfo.icon
                                : undefined
                        }
                        message={
                            this.state.messageInfo
                                ? this.state.messageInfo.message
                                : undefined
                        }
                        action={
                            this.state.messageInfo
                                ? this.state.messageInfo.action
                                : null
                        }
                    />
                </>
            );
        }
    };

export default withSnackbarHandler;
