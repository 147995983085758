import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import warning from 'warning';

import styles from './Heading.module.sass';

const Heading = React.forwardRef(function Heading(props, ref) {
    const {
        className,
        children,
        blue = false,
        tagName = 'h',
        level = 1
    } = props;

    warning(
        level >= 0 && level < 7,
        `[Heading]: this level \`${level}\` is not implemented.`
    );

    const Component = `${tagName}${level}`;
    return (
        <Component
            ref={ref}
            className={classNames(
                styles.h,
                styles[`h${level}`],
                {
                    [styles.blue]: blue
                },
                className
            )}
        >
            {children}
        </Component>
    );
});

Heading.propTypes = {
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * This means, that heading has `h1` or `h2` level analog
     */
    level: PropTypes.number,
    /**
     * The component used for the root node.
     * Either a string to use a DOM element or a component.
     */
    tagName: PropTypes.string,
    /**
     * @ignore
     */
    children: PropTypes.any,
    /**
     *
     */
    blue: PropTypes.bool
};

export default Heading;
