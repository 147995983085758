import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import warning from 'warning';

import styles from './Paper.module.sass';

const Paper = React.forwardRef(function Paper(props, ref) {
    const {
        className: classNameProp,
        component: Component = 'div',
        square = false,
        outlined = false,
        elevation = 1,
        ...other
    } = props;

    warning(
        elevation >= 0 && elevation < 25,
        `[Paper]: this elevation \`${elevation}\` is not implemented.`
    );

    const className = classnames(
        styles.root,
        {
            [styles[`elevation${elevation}`]]: !outlined,
            [styles.outlined]: outlined,
            [styles.rounded]: !square
        },
        classNameProp
    );

    return <Component className={className} ref={ref} {...other} />;
});

Paper.propTypes = {
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The component used for the root node.
     * Either a string to use a DOM element or a component.
     */
    component: PropTypes.elementType,
    /**
     * Shadow depth, corresponds to `dp` in the spec.
     * It accepts values between 0 and 24 inclusive.
     */
    elevation: PropTypes.number,
    /**
     * If `true`, rounded corners are disabled.
     */
    square: PropTypes.bool,
    /**
     * If `true`, show border.
     */
    outlined: PropTypes.bool
};

export default Paper;
