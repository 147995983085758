import React from 'react';

import axios from '../../shared/network/axios';
import AlertDialog from '../../components/Modal/Dialog/AlertDialog';

import Common from '../../components/Page/Common/Common';
import StatefulContainer from '../../components/UI/StatefulContainer';
import Dialog from '../../components/Modal/Dialog';
import Button from '../../components/UI/Button';
import Spinner from '../../components/UI/Spinner';

import style from './Keys.module.sass';


const Keys = () => {
    const [loading, setLoading] = React.useState(true);
    const [keys, setKeys] = React.useState([]);
    const [state, setState] = React.useState('empty');

    /**
     * Обновить ключи и установить состояние контейнера
     * в зависимости от набора данных ключей
     * @param keys {object[]} активные ключи
     */
    const updateKeys = keys => {
        setKeys(keys);
        setLoading(false);
        if (keys === null) {
            setState('error');
        } else if (keys.length === 0) {
            setState('empty');
        } else {
            setState('none');
        }
    };

    const getKeys = () => {
        setLoading(true);
        axios
            .get('keys')
            .then(response => {
                updateKeys(response.data);
            })
            .catch(() => {
                updateKeys(null);
            });
    };

    const issueKeys = (showDialog) => {
        const iss = () => {
            setLoading(true);
            axios.put('keys').then(response => {
                updateKeys(response.data);
            }).catch(() => {
                updateKeys(null);
            });
        };

        if (showDialog) {
            new AlertDialog.Builder()
                .withTitle(<Dialog.Title>Подтверждение перевыпуска</Dialog.Title>)
                .withDescription(<Dialog.Description>Вы действительно хотите перевыпустить имеющиеся приватный и
                    публичный ключи?</Dialog.Description>)
                .withButtons([
                    <Dialog.Button key={'issue'} onClick={iss}>Перевыпустить</Dialog.Button>,
                    <Dialog.Button key={'cancel'} appearance={'primary'}>Отмена</Dialog.Button>
                ]).build().show();
        } else {
            iss();
        }
    };

    const deleteKeys = () => {
        const del = () => {
            setLoading(true);
            axios.delete('keys').then(() => {
                updateKeys([]);
            }).catch(() => {
                updateKeys(null);
            });
        };

        new AlertDialog.Builder()
            .withTitle(<Dialog.Title>Подтверждение удаления</Dialog.Title>)
            .withDescription(<Dialog.Description>Вы действительно хотите удалить имеющиеся приватный и
                публичный ключи?</Dialog.Description>)
            .withButtons([
                <Dialog.Button key={'delete'} appearance={'error'} onClick={del}>Удалить</Dialog.Button>,
                <Dialog.Button key={'cancel'} appearance={'primary'}>Отмена</Dialog.Button>
            ]).build().show();
    };

    React.useEffect(() => {
        getKeys();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let content = <Spinner fixed />;

    if (!loading) {
        content = (
            <Common title={'Ключи'} returnUrl={'/'}>
                <StatefulContainer
                    state={state}
                    error={{ action: getKeys, actionText: 'Повторить попытку' }}
                    empty={{
                        text:
                            'Пока что у Вас нет ключей, но всё ещё можно изменить',
                        action: () => issueKeys(false),
                        actionText: 'Создать'
                    }}
                >
                    <table className={style.keys}>
                        <thead>
                        <tr>
                            <th>Ключ</th>
                            <th className={style.hideable}>Дата создания</th>
                        </tr>
                        </thead>
                        <tbody>
                        {keys && keys.map((k, i) => {
                            return (
                                <tr key={i}>
                                    <td><span className={style.legend}>{k.type}</span>{k.id}</td>
                                    <td className={style.hideable}>{k.createDate}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                    <div className={style.actions}>
                        <Button small bordered text={'Удалить'} onClick={deleteKeys} />
                        <Button small uppercase text={'Перевыпустить'} onClick={() => issueKeys(true)} />
                    </div>
                </StatefulContainer>
            </Common>
        );
    }

    return content;
};

export default Keys;
