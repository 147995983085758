import React from 'react';
import PropTypes from 'prop-types';

import axios from '../../shared/network/axios';

import AlertDialog from '../Modal/Dialog/AlertDialog';
import Dialog from '../Modal/Dialog';

import style from './Store.module.sass';

const Store = props => {

    const { info, deleteStoreHandler, loadingHandler } = props;

    const getStoreQR = () => {
        const fileName = `${info.id}.png`;
        const imageHref = `${process.env.REACT_APP_ID_PATH}stores/${encodeURI(fileName)}`;

        const download = () => {
            let a = document.createElement('a');
            a.href = `${imageHref}?download`;
            a.download = fileName;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        };

        new AlertDialog.Builder()
            .setOutsideNotClosable()
            .withTitle(<Dialog.Title>QR код магазина</Dialog.Title>)
            .withDescription(
                <Dialog.Description>
                    <img
                        className={style.qr}
                        src={imageHref}
                        alt={'QR code'} />
                </Dialog.Description>
            )
            .withButtons([
                <Dialog.Button
                    key={'download'}
                    appearance={'primary'}
                    onClick={download}
                >
                    Скачать
                </Dialog.Button>,
                <Dialog.Button key={'close'}>Закрыть</Dialog.Button>
            ])
            .build()
            .show();
    };

    const resetRequest = () => {
        loadingHandler && loadingHandler(true);
        axios.put(`stores/password?store=${info.id}`)
            .then(response => {
                loadingHandler && loadingHandler(false);

                new AlertDialog.Builder()
                    .setOutsideNotClosable()
                    .withTitle(<Dialog.Title>Новый пароль магазина</Dialog.Title>)
                    .withDescription(
                        <Dialog.Description>
                            Секретный код магазина <b id={'store-code'}>{response.data}</b>.
                            Запомните, или запишите его!
                        </Dialog.Description>
                    )
                    .build()
                    .show();
            })
            .catch(error => {
                loadingHandler && loadingHandler(false);
            });
    };

    const resetPassword = () => {
        new AlertDialog.Builder()
            .withTitle(<Dialog.Title>Подтверждение обновления пароля</Dialog.Title>)
            .withDescription(
                <Dialog.Description>
                    После обновления будет сгенерирован новый пароль. Вы действительно хотите обновить пароль
                    магазина <b>«{info.name}»</b>?
                </Dialog.Description>
            )
            .withButtons([
                <Dialog.Button key={'rest'} onClick={resetRequest}>Обновить</Dialog.Button>,
                <Dialog.Button appearance={'primary'} key={'cancel'}>Отмена</Dialog.Button>,
            ])
            .build()
            .show();
    };

    const deleteRequest = () => {
        loadingHandler && loadingHandler(true);
        axios.delete(`stores?store=${info.id}`)
            .then(response => {
                loadingHandler && loadingHandler(false);
                deleteStoreHandler && deleteStoreHandler(info);
            })
            .catch(error => {
                loadingHandler && loadingHandler(false);
            });
    };

    const deleteStore = () => {
        new AlertDialog.Builder()
            .withTitle(<Dialog.Title>Подтверждение удаления</Dialog.Title>)
            .withDescription(
                <Dialog.Description>
                    Вы действительно хотите удалить магазин <b>«{info.name}»</b>?
                </Dialog.Description>
            )
            .withButtons([
                <Dialog.Button key={'delete'} onClick={deleteRequest}>Удалить</Dialog.Button>,
                <Dialog.Button appearance={'primary'} key={'cancel'}>Отмена</Dialog.Button>,
            ])
            .build()
            .show();
    };

    const showStoreMenu = () => {

        new AlertDialog.Builder()
            .withButtons([
                <Dialog.Button
                    key='QR'
                    appearance={'primary'}
                    onClick={getStoreQR}
                >
                    QR код магазина
                </Dialog.Button>,
                <Dialog.Button
                    key='resetPassword'
                    onClick={resetPassword}
                >
                    Сбросить пароль
                </Dialog.Button>,
                <Dialog.Button
                    key='delete'
                    appearance={'error'}
                    onClick={deleteStore}
                >
                    Удалить магазин
                </Dialog.Button>,
                <Dialog.Button
                    key='cancel'
                >
                    Отмена
                </Dialog.Button>,
            ])
            .build()
            .show();
    };

    return (
        <tr onClick={showStoreMenu}>
            <td>{info.id}</td>
            <td>{info.name}</td>
        </tr>
    );
};

const storeShape = {
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string
};

Store.propTypes = {
    info: PropTypes.shape(storeShape),
    removeStoreHandler: PropTypes.func,
    loadingHandler: PropTypes.func
};

export default Store;
