import baseForm from '../baseForm';

export default {
    form: {
        typeId: {
            id: 'store_type',
            title: 'Тип платежа',
            type: 'select',
            value: 'form',
            valid: true,
            hidden: true
        },
        ...baseForm,
        successUrlMethod: {
            id: 'success_url_method',
            title: 'Success URL метод',
            type: 'radio',
            value: 'post',
            valid: true,
            values: [
                {
                    label: 'POST',
                    value: 'post',
                    defaultChecked: true
                },
                {
                    label: 'GET',
                    value: 'get',
                },
            ]
        },
        successUrl: {
            id: 'success_url',
            title: 'Success URL',
            type: 'text',
            valid: true,
            touched: false
        },
        failUrlMethod: {
            id: 'fail_url_method',
            title: 'Тип платежа',
            type: 'radio',
            value: 'post',
            valid: true,
            values: [
                {
                    label: 'POST',
                    value: 'post',
                    defaultChecked: true
                },
                {
                    label: 'GET',
                    value: 'get',
                },
            ]
        },
        failUrl: {
            id: 'fail_url',
            title: 'Fail URL',
            type: 'text',
            valid: true,
            touched: false
        },
        hashAlgorithmID: {
            id: 'hash_algorithm',
            title: 'Алгоритм расчета хэша',
            type: 'radio',
            value: 'SHA256',
            valid: true,
            values: [
                {
                    label: 'SHA256',
                    value: 'SHA256',
                    defaultChecked: true
                },
                {
                    label: 'SHA384',
                    value: 'SHA384',
                },
                {
                    label: 'SHA512',
                    value: 'SHA512',
                },
                {
                    label: 'MD5 (Не рекомендуется)',
                    value: 'MD5',
                },
                {
                    label: 'SHA1 (Не рекомендуется)',
                    value: 'SHA1',
                }
            ]
        },
    },
    isValid: false
};
