import React from 'react';
import { updateObject, checkValidity } from '../../../../shared/helpers';

const useFormFields = (initialForm) => {

    const [storeForm, setForm] = React.useState(initialForm);

    const formElements = [];
    const fields = storeForm.form;
    // eslint-disable-next-line no-unused-vars
    for (let key in fields) {
        formElements.push({
            id: key,
            config: fields[key]
        });
    }

    const inputChangedHandler = (value, inputId) => {
        const form = storeForm.form;
        const input = form[inputId];

        const inputUpdated = updateObject(input, {
            value: value,
            valid: checkValidity(value, input.validation),
            touched: true
        });

        const formUpdated = updateObject(form, {
            [inputId]: inputUpdated
        });

        let formIsValid = true;
        // eslint-disable-next-line no-unused-vars
        for (let input in formUpdated) {
            formIsValid = formUpdated[input].valid && formIsValid;
        }
        setForm({
            isValid: formIsValid,
            form: formUpdated
        });
    };

    return [formElements, inputChangedHandler, storeForm.isValid];
};

export default useFormFields;
