import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import TextArea from 'react-textarea-autosize';
import CurrencyFormat from 'react-currency-format';

import { ReactComponent as VisibleIcon } from './assets/visibility.svg';
import { ReactComponent as HiddenIcon } from './assets/visibility_off.svg';

import styles from './Input.module.sass';

const Input = React.forwardRef(function Input(props, ref) {

    const {
        className: classNameProp,
        disabled,
        hasErrors,
        label = '',
        labelUppercase,
        maxRowsCount = 5,
        multiRows,
        type: typeProp = 'text',
        value: valueProp = '',
        onChange,
        CompoundAction,
        maxYear = '2050',
        minYear = '1900',
        overrideOverLimitDate = true,
        overrideLowerLimitDate = true,
        bottomBorder = false,
        ...rest
    } = props;

    const [value, setValue] = React.useState(valueProp);
    const [focused, setFocused] = React.useState(false);
    const [hasValue, setHasValue] = React.useState(value !== '');
    const [error, setError] = React.useState(hasErrors);
    const [type, setType] = React.useState(typeProp);
    const [secure, setSecure] = React.useState(true);
    const [innerDatePlaceholder, setInnerDatePlaceholder] = React.useState(null);

    React.useEffect(() => {
        setError(hasErrors);
    }, [hasErrors]);

    React.useEffect(() => {
        setValue(valueProp);
        setHasValue(valueProp !== '');
    }, [valueProp]);

    const className = cx([
        classNameProp,
        styles.input,
        {
            [styles.error]: error,
            [styles.disabled]: disabled,
            [styles.focused]: focused,
            [styles.active]: hasValue,
            [styles.bottomBorder]: bottomBorder,
        }
    ]);

    if (!ref) {
        // set default ref
        ref = React.createRef();
    }

    const showHide = e => {
        e.preventDefault();
        e.stopPropagation();

        const nextType = type === 'password' ? 'input' : 'password';

        setType(nextType);
        setSecure(type !== 'password');
        ref.current.focus();
    };

    const onFocusHandler = () => {
        setFocused(true);
        setInnerDatePlaceholder("ДД.ММ.ГГГГ");
        setHasValue(true);
    };

    const onBlurHandler = () => {
        setFocused(false);
        setInnerDatePlaceholder(null);
        setHasValue(value !== '');
    };

    const onChangeHandler = e => {
        const val = e.target.value;
        setValue(val);
        setError(false);
        onChange && onChange(val, e);
    };

    const dateFormatter = val => {
        // get date from string
        let day = val.substring(0, 2);
        let month = val.substring(2, 4);
        let year = val.substring(4, 8);

        // day 32 → 3
        if(Number(day) > 31) day = day.substring(0, 1);
        // day 5 → 05 but 0, 1, 2, 3 remain the same
        if(day.length === 1 && Number(day) > 3) day = '0' + day;
        // month 2 → 02
        if(month.length === 1 && Number(month) > 1) month = '0' + month;
        // month 16 → 1
        if(month.length && month.substring(1, 2).length && Number(month) > 12 ) month = month.substring(0, 1);
        // 30.02 → 29.02
        if(month.length === 2){
            let daysInMonth = new Date(year.length < 4 ? 4 : Number(year), Number(month), 0).getDate();
            if(Number(day) > daysInMonth)
                day = daysInMonth;
        }
        // year 9 → empty string
        if(year.length && (Number(year.substring(0,1)) > 2 || Number(year.substring(0,1)) === 0)) year = "";
        if(year.length){
            // year 29 → max year or 2
            if(Number(year) > Number(maxYear.substring(0, year.length))) {
                year = overrideOverLimitDate ? maxYear : year.substring(0, year.length - 1);
            }
            // year 12 → min year or 1
            if(Number(year) < Number(minYear.substring(0, year.length)))
                year = overrideLowerLimitDate ? minYear : year.substring(0, year.length - 1);
        }

        // format date to DD.MM.YYYY
        return day + (month.length ? '.' + month : '') + (year.length ? '.' + year : '');
    };

    const input = () => {
        if (multiRows) {
            return (
                <TextArea
                    ref={ref}
                    rows='1'
                    maxRows={maxRowsCount}
                    onFocus={onFocusHandler}
                    onBlur={onBlurHandler}
                    onChange={onChangeHandler}
                    disabled={disabled}
                    value={value}
                    {...rest}
                />
            );
        }

        if (type === 'amount') {
            return (
                <CurrencyFormat
                    ref={ref}
                    onFocus={onFocusHandler}
                    onBlur={onBlurHandler}
                    onChange={onChangeHandler}
                    disabled={disabled}
                    value={value}
                    {...rest}
                />
            );
        }

        if (type === 'date') {
            return (
                <CurrencyFormat
                    ref={ref}
                    onFocus={onFocusHandler}
                    onBlur={onBlurHandler}
                    onChange={onChangeHandler}
                    disabled={disabled}
                    value={value}
                    placeholder={innerDatePlaceholder}
                    format={dateFormatter}
                    {...rest}
                />
            );
        }

        return (
            <input
                ref={ref}
                onFocus={onFocusHandler}
                onBlur={onBlurHandler}
                onChange={onChangeHandler}
                type={type}
                disabled={disabled}
                value={value}
                {...rest}
            />
        );
    };

    return (
        <div className={className}>
            <div className={styles.wrap}>
                {input()}
                {label && (
                    <label className={cx({
                        [styles.uppercase]: labelUppercase
                    })}>
                        {label}
                    </label>
                )}
            </div>
            {typeProp === 'password' && (
                <div onClick={showHide} className={styles.action}>
                    {secure ? <VisibleIcon /> : <HiddenIcon />}
                </div>
            )}
            {CompoundAction && (
                <div className={styles.action}>{CompoundAction}</div>
            )}
        </div>
    );
});

Input.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    hasErrors: PropTypes.bool,
    label: PropTypes.string,
    labelUppercase: PropTypes.bool,
    maxRowsCount: PropTypes.number,
    multiRows: PropTypes.bool,
    onChange: PropTypes.func,
    maxYear: PropTypes.number,
    minYear: PropTypes.number,
    overrideOverLimitDate: PropTypes.bool,
    overrideLowerLimitDate: PropTypes.bool,
    type: PropTypes.string,
    CompoundAction: PropTypes.any,
    bottomBorder: PropTypes.bool,
};

export default Input;
