import React from 'react';
import { withRouter } from 'react-router-dom';

import StatefulContainer from '../../UI/StatefulContainer';
import Common from '../Common';
import image404 from '../../../assets/lottie/404';

const Page404 = ({ history }) => {
    return (
        <Common>
            <StatefulContainer state={'empty'} empty={{
                lottie: image404,
                text:
                    'Куда бы тебя не занесло, путник, ты всегда можешь вернуться назад…',
                action: history.goBack,
                actionText: 'Вернуть как было'
            }} />
        </Common>
    );
};

export default withRouter(Page404);
