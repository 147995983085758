import warning from 'warning';

export const updateObject = (oldState, updatedProperties) => {
    return {
        ...oldState,
        ...updatedProperties
    };
};

export const UUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

export const checkValidity = (value, rules) => {
    let isValid = true;
    if (!rules) {
        return isValid;
    }
    const inputValue = value.trim();
    if (rules.required) {
        isValid = inputValue !== '' && isValid;
    }
    if (rules.minLength) {
        isValid = inputValue.length >= rules.minLength && isValid;
    }
    if (rules.maxLength) {
        isValid = inputValue.length <= rules.maxLength && isValid;
    }
    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid;
    }
    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid;
    }
    return isValid;
};

/**
 * Safely accessing deeply nested values
 *
 * @example
 * // TEST JSON
 * const props = {
 *      user: {
 *           posts: [
 *                { title: 'Foo', comments: ['Good one!', 'Interesting...'] },
 *                { title: 'Bar', comments: ['Ok'] },
 *                { title: 'Baz', comments: [] },
 *           ]
 *       }
 * }
 *
 * // DO:
 * get(['user', 'posts', '0', 'comments'], props)
 *
 * // RETURNS
 * [ 'Good one!', 'Interesting...' ]
 *
 * @param {string[]} path path to interested property as array of key names
 * @param {object} object object to get property value
 *
 * @returns value or null
 */
export const get = (path, object) =>
    path.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), object);

/**
 * Capitalize only first word
 *
 * @param {string} string sentence / word what need to capitalize
 * @returns {string} with capitalized first character
 */
export const capitalize = string => {
    if (process.env.NODE_ENV !== 'production' && typeof string !== 'string') {
        throw new Error(
            '[utility.js]: capitalize(string) expects a string argument.'
        );
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Safe chained function
 *
 * Will only create a new function if needed,
 * otherwise will pass back existing functions or null.
 *
 * @param {function} functions to chain
 * @returns {function|null}
 */
export const createChainedFunction = (...funcs) => {
    return funcs.reduce(
        (acc, func) => {
            if (func == null) {
                return acc;
            }

            warning(
                typeof func === 'function',
                '[utility.js]: invalid Argument Type, must only provide functions, undefined, or null.'
            );

            return function chainedFunction(...args) {
                acc.apply(this, args);
                func.apply(this, args);
            };
        },
        () => {
        }
    );
};

/**
 * Форматирует строку и число в нужном падеже.
 *
 * @example
 *
 * //DO:
 * formatWithEndings(3, ['день', 'дня', 'дней'])
 *
 * // RETURNS
 * '3 дня'
 *
 * @param {number} number Число с которым должна проспряжаться строка
 * @param {string[]} endings Массив спряжений по родам для чисел 1, 4, 5
 *
 * @returns Строку с правильным спряжением и числом
 */
export const formatWithEndings = (number, endings) => {
    let sEnding = '';
    let num = 0;

    num = number % 100;
    if (num >= 11 && num <= 19) {
        sEnding = endings[2];
    } else {
        switch (num % 10) {
            case 1:
                sEnding = endings[0];
                break;
            case 2:
            case 3:
            case 4:
                sEnding = endings[1];
                break;
            default:
                sEnding = endings[2];
        }
    }

    return `${number} ${sEnding}`;
};

export const prettyDate = (dateStr, locale) => {

    let prettyDate = null;

    prettyDate = new Date(dateStr.split('.').reverse().join('-'))
        .toLocaleDateString(locale ? locale : 'ru-RU', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        })
        .split(' ')
        .join(' ');

    return prettyDate;
};
