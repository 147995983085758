import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';

import styles from './Item.module.sass';

const TabBarItem = (props) => {
    const {
        className: classNameProp,
        children,
        exact,
        link,
        query,
        Icon
    } = props;
    const [active, setActive] = React.useState(false);
    const className = cx(
        styles.item,
        { [styles.faded]: active },
        classNameProp
    );

    const onTouchStart = () => {
        setActive(true);
    };

    const onTouchEnd = () => {
        setActive(false);
    };

    return (
        <NavLink
            className={className}
            activeClassName={styles.active}
            exact={exact}
            to={{ pathname: link, search: query }}
            onMouseDown={onTouchStart}
            onMouseUp={onTouchEnd}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            onTouchCancel={onTouchEnd}
            onDragEnd={onTouchEnd}
        >
            <span className={styles.content}>
                {Icon && <Icon className={styles.icon} />}
                <span className={styles.text}>{children}</span>
            </span>
        </NavLink>
    );
};

TabBarItem.displayName = 'TabBarItem';

TabBarItem.propTypes = {
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * Text of current TabBar Item
     */
    children: PropTypes.string.isRequired,
    /**
     * SVG React-like component
     */
    Icon: PropTypes.elementType.isRequired,
    /**
     * Link to navigation
     */
    link: PropTypes.string.isRequired,
    /**
     * Path query params
     */
    query: PropTypes.string
};

export default TabBarItem;
