import React from 'react';

import RadioGroup from '../../../../components/UI/RadioGroup';
import Input from '../../../../components/UI/Input';
import { get } from '../../../../shared/helpers';

const useDrawForm = (fields, inputChangeHandler) => {
        const form = fields.map(el => (
            !(get(['config', 'hidden'], el) || false)
                ? el.config.type === 'radio'
                ? <RadioGroup
                    key={el.id}
                    name={el.id}
                    options={[...el.config.values]}
                    onChange={val => inputChangeHandler(val, el.id)} />
                : <Input
                    key={el.id}
                    name={el.id}
                    id={el.config.id}
                    label={el.config.title}
                    type={el.config.type}
                    hasErrors={!el.config.valid && el.config.touched}
                    onChange={val => inputChangeHandler(val, el.id)} />
                : null
        ));
        return form;
    }
;

export default useDrawForm;
