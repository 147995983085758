import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Control from '../Control';

export default class Radio extends Component {
    static propTypes = {
        className: PropTypes.string,
        defaultChecked: PropTypes.bool,
        label: PropTypes.string,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func
    };

    render() {
        return <Control {...this.props} type='radio' />;
    }
}
