import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Control.module.sass';

export default class Control extends Component {
    static propTypes = {
        className: PropTypes.string,
        defaultChecked: PropTypes.bool,
        label: PropTypes.string,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        type: PropTypes.oneOf(['radio', 'checkbox'])
    };

    static defaultProps = {
        defaultChecked: false
    };

    state = {
        active: this.props.defaultChecked
    };

    onChange = e => {
        this.setState((prevState) => {
            return {
                active: !prevState.active
            };
        });

        if (this.props.onChange) this.props.onChange(e);
    };

    render() {
        return (
            <label
                className={classNames([
                    styles.control,
                    {
                        [styles[`${this.props.type}`]]: this.props.type,
                        [this.props.className]: this.props.className
                    }
                ])}
                title={this.props.label}
            >
                <input
                    onChange={this.onChange}
                    type={this.props.type}
                    className={styles.field}
                    name={this.props.name}
                    defaultChecked={this.props.defaultChecked}
                />
                <span className={styles.label}>{this.props.label}</span>
            </label>
        );
    }
}
