import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Item from './Item';

import styles from './TabBar.module.sass';

const TabBar = (props) => {
    const { className: classNameProp, children } = props;

    const className = cx(styles.tabBar, classNameProp);
    return (
        <div className={className}>
            {children}
        </div>
    );
};

TabBar.displayName = 'TabBar';

TabBar.propTypes = {
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * Array of [<TabBar.Item />]('./Item)
     */
    children: PropTypes.arrayOf(PropTypes.element)
};

TabBar.Item = Item;

export default TabBar;
