import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './DialogTitle.module.sass';

const DialogTitle = React.forwardRef(function DialogTitle(props, ref) {
    const { className: classNameProp, children, ...rest } = props;

    const className = classnames(styles.title, classNameProp);
    return (
        <h3 ref={ref} className={className} {...rest}>
            {children}
        </h3>
    );
});

DialogTitle.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string
};

DialogTitle.displayName = 'DialogTitle';

export default DialogTitle;
