import DialogButton from './DialogButton';
import DialogContainer from './DialogContainer';
import DialogDescription from './DialogDescription';
import DialogInput from './DialogInput';
import DialogTitle from './DialogTitle';

export default {
    Button: DialogButton,
    Container: DialogContainer,
    Description: DialogDescription,
    Input: DialogInput,
    Title: DialogTitle
};
