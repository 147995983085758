import React from 'react';
import Paragraph from '../../../components/UI/Paragraph';
import axios from '../../../shared/network/axios';
import CurrencyFormat from 'react-currency-format';
import styles from './Payment.module.sass';
import done from '../../../assets/lottie/checked-done';
import ruStrings from 'react-timeago/lib/language-strings/ru';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import TimeAgo from 'react-timeago';
import Lottie from 'lottie-react-web';
import moment from 'moment';
import withSnackbarHandler from '../../../hoc/withSnackbarHandler';

const formatter = buildFormatter(ruStrings);

const Payment = ({ data, snackbarShowHandler }) => {

    let [paid, setPaid] = React.useState(data.paid);

    React.useEffect(() => {

        let timer = setInterval(() => {
            if (!paid && moment().diff(moment(data.expires, 'DD.MM.YYYY HH:mm')) < 0) {
                axios.get('/code', {
                    params: {
                        code: data.code
                    }
                }).then(response => {
                    if (response.data.paid) {
                        setPaid(response.data.paid);
                        snackbarShowHandler(`Код ${data.code} оплачен`);
                        clearInterval(timer);
                    }
                }).catch(err => console.log(err));
            }
        }, 3000);

        return () => {
            if (timer) clearInterval(timer);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div className={styles.payment}>
            {data.code &&
            <div className={styles.code}>{data.code}</div>}
            <div className={styles.details}>
                <Paragraph>Сумма <CurrencyFormat
                    displayType={'text'}
                    value={data.sum}
                    suffix={' руб. ПМР'}
                    thousandSeparator={' '}
                    decimalSeparator={'.'}
                    decimalScale={2}
                /></Paragraph>
                <Paragraph>Создан <TimeAgo date={moment(data.create, 'DD.MM.yyyy hh:mm')}
                                           formatter={formatter} /></Paragraph>
            </div>
            {paid && <div className={styles.done}><Lottie
                direction={1}
                isStopped={false}
                options={{
                    animationData: done,
                    loop: false,
                    autoplay: true
                }}
            /></div>}
        </div>
    );
};

export default withSnackbarHandler(Payment);
