import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './Paragraph.module.sass';

const Paragraph = React.forwardRef(function Paragraph(props, ref) {
    const {
        blue = false,
        tint = false,
        tagName: Component = 'p',
        children,
        className,
        mode
    } = props;

    return (
        <Component
            ref={ref}
            className={classnames(styles.p, className, {
                [styles[`p-${mode}`]]: mode,
                [styles.blue]: blue,
                [styles.tint]: tint
            })}
        >
            {children}
        </Component>
    );
});

Paragraph.propTypes = {
    /**
     * If `true` show text with blue color.
     */
    blue: PropTypes.bool,
    tint: PropTypes.bool,
    /**
     * HTML tag representation.
     */
    tagName: PropTypes.string,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * @ignore
     */
    children: PropTypes.any,
    /**
     * Text style appearance.
     */
    mode: PropTypes.oneOf([
        'subtitle',
        'body',
        'body-medium',
        'body-small',
        'bold',
        'bold-medium',
        'bold-small'
    ])
};

export default Paragraph;
