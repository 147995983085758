import baseForm from '../baseForm';

export default {
    form: {
        typeId: {
            id: 'store_type',
            title: 'Тип платежа',
            type: 'select',
            value: 'code',
            valid: true,
            hidden: true
        },
        ...baseForm,
        host: {
            id: 'host',
            title: 'HOST/IP продавца',
            type: 'text',
            valid: true,
            touched: false
        },
        codeLifetime: {
            id: 'lifetime',
            title: 'Время жизни кода в минутах',
            type: 'tel',
            validation: {
                isNumeric: true
            },
            valid: true,
            touched: false
        }
    },
    isValid: false
};
