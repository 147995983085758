import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Paper from '../../Paper';

import styles from './SnackbarContent.module.sass';

const SnackbarContent = React.forwardRef(function SnackbarContent(props, ref) {
    const { action, className, message, icon, ...other } = props;

    return (
        <Paper
            elevation={6}
            className={classnames(styles.root, className)}
            ref={ref}
            {...other}
        >
            <div className={styles.message}>
                <span>
                    {icon}
                    {message}
                </span>
            </div>
            {action ? <div className={styles.action}>{action}</div> : null}
        </Paper>
    );
});

SnackbarContent.propTypes = {
    /**
     * The action to display.
     */
    action: PropTypes.node,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The message to display.
     */
    message: PropTypes.node,
    /**
     * The icon to display.
     */
    icon: PropTypes.node
};

export default SnackbarContent;
