import React, { Component } from 'react';
import { withRouter } from 'react-router';

import axios from '../../../shared/network/axios';
import token from '../../../shared/token';

import Common from '../../../components/Page/Common';
import StatefulContainer from '../../../components/UI/StatefulContainer';
import Payment from '../Payment';
import Button from '../../../components/UI/Button';
import imageOffice from '../../../assets/lottie/img-office';
import style from './PaymentList.module.sass';

class PaymentList extends Component {

    timer = null;
    state = {
        data: [],
        state: 'empty'
    };

    updateState = (data) => {
        this.setState({ data: data });
        if (data === null) {
            this.setState({ state: 'error' });
        } else if (data.length === 0) {
            this.setState({ state: 'empty' });
        } else {
            this.setState({ state: 'none' });
        }
    };

    load = () => {
        axios.get('/code', {
            params: {
                show_expired: false
            }
        })
            .then(response => {
                this.updateState(response.data);
                this.setState({ data: response.data });
            })
            .catch(reason => {
                if (reason.response.status === 401) {
                    token.clear();
                    this.props.history.push('/auth');
                }
            });
    };

    componentDidMount() {
        this.load();
        this.timer = setInterval(() => {
            this.load();
        }, 10000);
    }

    componentWillUnmount() {
        if (this.timer) clearInterval(this.timer);
    }

    add = () => {
        this.props.history.push('add');
    };

    render() {
        return (
            <Common title={'Коды оплаты'}>
                <StatefulContainer
                    state={this.state.state}
                    empty={{
                        lottie: imageOffice,
                        text: 'Сейчас нет активных кодов для оплаты.',
                        action: localStorage.getItem("store") === "true" ? this.add : null,
                        actionText: 'Создать платеж'
                    }}>
                    { localStorage.getItem("store") === "true" && <Button text={'+'} small uppercase className={style.buttonAdd} onClick={this.add} /> }
                    {
                        this.state.data &&
                        this.state.data.map((item, i) => {
                            return <Payment data={item}
                                            key={`payment-${item.invoice_id}`} />;
                        })
                    }
                </StatefulContainer>
            </Common>
        );
    }
}

export default withRouter(PaymentList);
