import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import style from './Form.module.sass';

const Form = ({ children, className: classNameProp }, ...rest) => {
    const className = cx(style.form, classNameProp);
    return <div className={className}>{children}</div>;
};

Form.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string
};

export default Form;
