import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { findByType } from '../../../../shared/reactHelpers';

import ClickAwayListener from '../../../UI/Common/ClickAwayListener';
import Backdrop from '../../../UI/Backdrop/Backdrop';
import Paper from '../../../UI/Paper';
import DialogTitle from '../DialogTitle';
import DialogDescription from '../DialogDescription';
import DialogButton from '../DialogButton';

import styles from './DialogContainer.module.sass';
import DialogInput from "../DialogInput";

const DialogContainer = React.forwardRef((props, ref) => {
    const { children, closeOutside = true, onClose } = props;

    // Close dialog on `ESC` keyboard button
    const onKeyPressHandler = e => {
        closeOutside && e.keyCode === 27 && onClose && onClose();
    };

    React.useEffect(
        () => {
            document.onkeyup = onKeyPressHandler;

            // `componentWillUnmount`
            return () => {
                document.onkeyup = null;
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            // empty array indicates, that `useEffect` called once
            // only on `componentDidMount`
        ]
    );

    const renderMessage = () => {
        const title = findByType(children, DialogTitle)[0];
        const description = findByType(children, DialogDescription)[0];

        if (!title && !description) {
            return null;
        }

        return (
            <div className={styles.message}>
                {title}
                {description}
            </div>
        );
    };

    const renderFields = () => {
        const inputs = findByType(children, DialogInput);

        if (inputs.length) return (
                <span className={styles.fields}>
                    {inputs.map((e, i) =>
                        React.cloneElement(e, {
                            key: `dialog-input-${i}`
                        })
                    )}
                </span>
            );
    };

    const renderButtons = () => {
        const buttons = findByType(children, DialogButton);

        return (
            <div className={styles.actions} onClick={onClose}>
                {buttons.length ? (
                    buttons.map((b, i) =>
                        React.cloneElement(b, {
                            key: `dialog-button-${i}`,
                            className: styles.button,
                            tabIndex: 0
                        })
                    )
                ) : (
                    <DialogButton className={styles.button} tabIndex={0}>
                        ОК
                    </DialogButton>
                )}
            </div>
        );
    };

    const content = (
        <Paper elevation={0} className={styles.dialog}>
            <div className={styles.container}>
                {renderMessage()}
                {renderFields()}
                {renderButtons()}
            </div>
        </Paper>
    );

    const onCloseOutside = closeOutside && onClose
        ? onClose
        : () => {
        };

    return ReactDOM.createPortal(
        <Backdrop ref={ref} show className={styles.backdrop}>
            <ClickAwayListener onClickAway={onCloseOutside}>
                {content}
            </ClickAwayListener>
        </Backdrop>,
        document.body
    );
});

DialogContainer.propTypes = {
    children: PropTypes.any,
    closeOutside: PropTypes.bool,
    onClose: PropTypes.func
};

DialogContainer.displayName = 'DialogContainer';

export default DialogContainer;
