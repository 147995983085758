import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Radio from '../Radio';

import style from './RadioGroup.module.sass';

class RadioGroup extends React.Component {

    state = {
        value: null
    };

    onChange = value => {
        this.setState({ value: value });
        this.props.onChange && this.props.onChange(value);
    };

    render() {
        let group = null;

        if (this.props.options) {
            group = (
                <div className={cx(style.group, this.props.className)}>
                    {this.props.options.map((option, i) => (
                            <Radio
                                label={option.label}
                                value={option.value}
                                onChange={() => this.onChange(option.value)}
                                name={`option-${this.props.name}`}
                                key={`${this.props.name}--option-${
                                    option.value
                                }`}
                                defaultChecked={option.defaultChecked}
                            />
                        )
                    )}
                </div>
            );
        }

        return group;
    }
}

const optionShape = {
    label: PropTypes.string,
    value: PropTypes.string,
    defaultChecked: PropTypes.bool
};

RadioGroup.propTypes = {
    options: PropTypes.arrayOf(
            PropTypes.shape(optionShape)
    )
};

export default RadioGroup;

