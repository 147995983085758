const TOKEN_KEY = "token";

export default new class Token {
    get = () => {
        return localStorage.getItem(TOKEN_KEY)
    };

    set = (login, password) => {
      localStorage.setItem(TOKEN_KEY, btoa(`${login}:${password}`))
    };

    clear = () => {
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem("store")
    }
}();
