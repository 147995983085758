import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './DialogButton.module.sass';

const DialogButton = React.forwardRef((props, ref) => {
    const {
        className: classNameProp,
        children,
        appearance = 'default',
        onClick,
        ...rest
    } = props;

    const className = classnames(
        styles.button,
        styles[`${appearance}`],
        classNameProp
    );

    return (
        <button ref={ref} className={className} onClick={onClick} {...rest}>
            {children}
        </button>
    );
});

DialogButton.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    appearance: PropTypes.oneOf(['default', 'primary', 'error']),
    onClick: PropTypes.func
};

DialogButton.displayName = 'DialogButton';

export default DialogButton;
