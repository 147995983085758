import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './DialogDescription.module.sass';

const DialogDescription = React.forwardRef((
    props,
    ref
) => {
    const { className: classNameProp, children } = props;

    const className = classnames(styles.description, classNameProp);
    return (
        <div ref={ref} className={className}>
            {children}
        </div>
    );
});

DialogDescription.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string
};

DialogDescription.displayName = 'DialogDescription';

export default DialogDescription;
