import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';

import './index.sass';

import App from './App';

axios.defaults.timeout = 120000;
axios.defaults.baseURL = 'http://merchant.prisbank.com/';

const app = (
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

ReactDOM.render(app, document.getElementById('root'));
