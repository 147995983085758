import React from 'react';
import { withRouter } from 'react-router';

import Heading from '../../UI/Heading';
import { ReactComponent as ArrowLeft } from '../../../assets/images/ic-arrow-left.svg';

import styles from './Title.module.sass';

const Title = ({ backUrl, title, history }) => {
    const onClickBack = () => {
        history.push(backUrl);
    };

    return (
        <div className={styles.title}>
            {
                backUrl &&
                <ArrowLeft
                    onClick={onClickBack}
                    className={styles.backButton}
                    width={'100%'}
                    height={'100%'} />
            }
            <Heading className={styles.header} level={2}>{title}</Heading>
        </div>
    );
};

export default withRouter(Title);
