import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ReactComponent as ArrowIcon } from './assets/arrow-link.svg';

import styles from './Button.module.sass';

const Button = React.forwardRef(function Button(props, ref) {
    const {
        arrowed,
        bordered,
        className,
        disabled,
        loading,
        onClick,
        small,
        text,
        type = 'button',
        uppercase
    } = props;

    const [active, setActive] = React.useState(false);

    const onTouchStart = () => {
        setActive(true);
    };

    const onTouchEnd = () => {
        setActive(false);
    };

    const onClickHandler = e => {
        if (disabled || loading) {
            e.preventDefault();
            return;
        }

        setActive(false);
        if (onClick) {
            e.currentTarget.blur();
            onClick(e);
        }
    };

    const buttonContent = loading ? (
        <span className={styles.inner}>
            {arrowed ? text : ''} <i className={styles.whirl}> </i>
        </span>
    ) : (
        <span className={styles.inner}>
            <span className={styles.text}>{text}</span>
            {arrowed && <ArrowIcon className={styles.arrow} />}
        </span>
    );

    return (
        <button
            className={classNames([
                styles.btn,
                {
                    [styles.active]: active,
                    [styles.arrowed]: arrowed,
                    [styles.bordered]: bordered,
                    [styles.disabled]: disabled,
                    [styles.small]: small,
                    [styles.uppercase]: uppercase,
                    [styles.oneSymbol]: text.length === 1
                },
                className
            ])}
            disabled={disabled}
            type={type}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            onTouchCancel={onTouchEnd}
            onClick={onClickHandler}
            ref={ref}
        >
            {buttonContent}
        </button>
    );
});

Button.propTypes = {
    arrowed: PropTypes.bool,
    bordered: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    small: PropTypes.bool,
    text: PropTypes.string,
    type: PropTypes.oneOf(['submit', 'reset', 'button']),
    uppercase: PropTypes.bool
};

export default Button;
