import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './Logo.module.sass';

import { ReactComponent as LogoIcon } from './assets/logo.svg';

const Logo = React.forwardRef(function Logo(props, ref) {
    const { className, postfix } = props;
    return (
        <div className={classnames([styles.logo, className])} ref={ref}>
            <LogoIcon className={styles.icon} />
            {postfix && <span className={styles.postfix}>{postfix}</span>}
        </div>
    );
});

Logo.propTypes = {
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * Logo text postfix
     */
    postfix: PropTypes.string
};

export default Logo;
