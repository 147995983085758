import React from 'react';
import { withRouter } from 'react-router';
import qs from 'qs';

import axios from '../../../shared/network/axios';
import shortid from 'shortid';
import withSnackbarHandler from '../../../hoc/withSnackbarHandler';

import Input from '../../../components/UI/Input';
import Button from '../../../components/UI/Button';
import Paragraph from '../../../components/UI/Paragraph';
import Common from '../../../components/Page/Common/Common';

import styles from './NewPayment.module.sass';
import Form from '../../../components/Page/Form';

const NewPayment = ({ data, history, snackbarShowHandler, ...other }) => {

    let textInput = React.createRef();
    let [error, setError] = React.useState(false);

    let create = e => {
        if (e) e.preventDefault();
        if (!textInput.current.state.value || textInput.current.state.value === '0') {
            setError(true);
        } else {
            axios({
                method: 'post',
                url: 'code',
                data: qs.stringify({
                    store_id: localStorage.getItem('store_id'),
                    invoice_id: shortid.generate(),
                    sum: textInput.current.state.value
                }),
                headers: { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' },
            }).then(createdCode => {
                history.push(`code/${createdCode.data.code}`);
            }).catch(reason => {
                setError(true);
            });
        }
    };

    return (
        <Common returnUrl="/" title="Новый платеж">
            <Form>
                <Paragraph>Создайте новый платеж для оплаты мобильным платежом. Для этого введите сумму платежа в рублях
                    ПМР и скажите клиенту сгенерированный код.</Paragraph>
                <form onSubmit={create}>
                    <Input ref={textInput} label={'Сумма'} type={'amount'} hasErrors={error} />
                    <Button type='submit' className={styles.createButton} arrowed bordered text={'Создать'} />
                </form>
            </Form>
        </Common>
    );
};

export default withSnackbarHandler(withRouter(NewPayment));
