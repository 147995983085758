import React from 'react';

import Toolbar from '../Toolbar/Toolbar';
import Title from '../Title/Title';

import styles from './Common.module.sass';

const Common = ({ children, returnUrl, title }) => {
    return (
        <>
            <Toolbar />
            <div className={styles.wrap}>
                {(returnUrl || title) && <Title backUrl={returnUrl} title={title} />}
                {children}
            </div>
        </>
    );
};

export default Common;
