import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './Spinner.module.sass';

const Spinner = React.forwardRef(function Spinner(props, ref) {
    const { fixed, className: classNameProp } = props;

    const className = classnames(
        styles.spinner,
        { [styles.fixed]: fixed },
        classNameProp
    );

    return (
        <div ref={ref} className={className}>
            Загрузка…
        </div>
    );
});

Spinner.propTypes = {
    /**
     * If `true` shows spinned fixed (center screen).
     */
    fixed: PropTypes.bool,
    /**
     * @ignore
     */
    className: PropTypes.string
};

export default Spinner;
