/**
 * Creates a new queue.
 *
 * A queue is a first-in-first-out (FIFO) data structure -
 * items are added to the end of the queue and removed from the front.
 */
export default class Queue {
    constructor() {
        this.queue = [];
    }

    /**
     * Enqueues the specified item.
     *
     * @param item the item to enqueue
     */
    enqueue = item => {
        this.queue.push(item);
    };

    /**
     * Dequeues an item and returns it.
     * If the queue is empty, the value 'undefined' is returned.
     *
     * @returns value or 'undefined' if queue is empty
     */
    dequeue = () => {
        return this.queue.shift();
    };

    /**
     * Returns the item at the front of the queue (without dequeuing it).
     * If the queue is empty then 'undefined' is returned.
     *
     * @returns item without dequeuing or 'undefined' if queue is empty
     */
    peek = () => {
        return this.queue.length > 0 ? this.queue[0] : undefined;
    };

    /**
     * Clear current queue.
     */
    clear = () => {
        this.queue = [];
    };

    /**
     * Get queue length.
     *
     * @returns queue length
     */
    get length() {
        return this.queue.length;
    }

    /**
     * Returns the first item of the queue (without dequeuing it).
     * If the queue is empty then 'undefined' is returned.
     *
     * @returns item without dequeuing or 'undefined' if queue is empty
     */
    get first() {
        return this.peek();
    }

    /**
     * Get info is queue empty or not.
     *
     * @returns true if queue is empty, otherwise false
     */
    get isEmpty() {
        return this.queue.length === 0;
    }
}
