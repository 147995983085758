import React from 'react';

import axios from '../../../shared/network/axios';

import Common from '../../../components/Page/Common/Common';
import StatefulContainer from '../../../components/UI/StatefulContainer';
import Button from '../../../components/UI/Button';
import Spinner from '../../../components/UI/Spinner';
import Store from '../../../components/Store';

import style from './StoreList.module.sass';

const StoreList = ({ history }) => {
    const [loading, setLoading] = React.useState(true);
    const [stores, setStores] = React.useState([]);
    const [state, setState] = React.useState('empty');

    /**
     * Обновить список магазинов и установить состояние контейнера
     * в зависимости от количества магазинов
     * @param stores {object[]} активные ключи
     */
    const updateStores = stores => {
        setStores(stores);
        setLoading(false);
        if (stores === null) {
            setState('error');
        } else if (stores.length === 0) {
            setState('empty');
        } else {
            setState('none');
        }
    };

    const getStores = () => {
        setLoading(true);
        axios
            .get('stores')
            .then(response => {
                updateStores(response.data);
            })
            .catch(() => {
                updateStores(null);
            });
    };

    const addStore = () => {
        history.push('/stores/add');
    };

    const removeStore = info => {
        const index = stores.indexOf(info);
        if (index > -1) {
            let storesUpdates = [...stores];
            storesUpdates.splice(index, 1);
            setStores([...storesUpdates]);
        }
    };

    React.useEffect(() => {
        getStores();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let content = <Spinner fixed />;

    if (!loading) {
        content = (
            <Common title={'Магазины'} returnUrl={'/'}>
                <StatefulContainer
                    state={state}
                    error={{
                        action: getStores,
                        actionText: 'Повторить попытку'
                    }}
                    empty={{
                        text:
                            'Пока что у Вас нет магазинов, но всё ещё можно изменить',
                        action: addStore,
                        actionText: 'Создать'
                    }}>
                    <Button small uppercase text={'+'} className={style.buttonAdd} onClick={addStore} />
                    <table className={style.stores}>
                        <thead>
                        <tr>
                            <th>Код</th>
                            <th>Название</th>
                        </tr>
                        </thead>
                        <tbody>
                        {stores && stores.map((s, i) =>
                            <Store key={s.id} info={s} deleteStoreHandler={removeStore} loadingHandler={setLoading} />
                        )}
                        </tbody>
                    </table>
                </StatefulContainer>
            </Common>
        );
    }

    return content;
};

export default StoreList;
