import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import Common from '../../../components/Page/Common';
import Form from '../../../components/Page/Form';
import StoreByForm from './Form';
import StoreByCode from './Code';
import TabBar from '../../../components/Navigation/TabBar';
import { ReactComponent as IconWeb } from '../../../assets/images/ic-web.svg';
import { ReactComponent as IconMobile } from '../../../assets/images/ic-mobile-friendly.svg';

import style from './StoreAdd.module.sass';

const StoreAdd = () => {
    return (
        <Common title={'Магазин'} returnUrl={'/stores'}>
            <Form>
                <TabBar>
                    <TabBar.Item Icon={IconMobile} link={'/stores/add/code'}>Мобильный платеж</TabBar.Item>
                    <TabBar.Item Icon={IconWeb} link={'/stores/add/form'}>Интернет-магазин</TabBar.Item>
                </TabBar>
                <div className={style.wrap}>
                    <Switch>
                        <Route exact path={'/stores/add/code'} component={StoreByCode} />
                        <Route exact path={'/stores/add/form'} component={StoreByForm} />
                        <Redirect to={'/stores/add/code'} />
                    </Switch>
                </div>
            </Form>
        </Common>
    );
};

export default StoreAdd;
