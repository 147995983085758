import React from 'react';
import ReactDOM from 'react-dom';

import Dialog from '.';

/**
 * Simple Modal dialog builder.
 *
 * @author Sergey Gagauz
 *
 * @example
 * //DO
 * let dialog = new AlertDialog.Builder()
 *    .withTitle(<Dialog.Title>Title</Dialog.Title>)
 *    .withDescription(
 *        <Dialog.Description>Description</Dialog.Description>
 *    )
 *    .appendButton(
 *        <Dialog.Button appearance='error'>Button</Dialog.Button>
 *    )
 *    .appendButton(
 *        <Dialog.Button appearance='primary'>Button</Dialog.Button>
 *    )
 *    .appendButton(<Dialog.Button>Button</Dialog.Button>)
 *    .setOutsideNotClosable()
 *    .build();
 *
 * //DISPLAY
 * dialog.show();
 *
 * //DISMISS
 * dialog.dismiss()
 */
class AlertDialog {
    /**
     * @typedef {node} Dialog.Title See [DialogTitle]('./DialogTitle/DialogTitle.js') for details.
     * @typedef {node} Dialog.Description See [DialogDescription]('./DialogDescription/DialogDescription.js') for details.
     * @typedef {node} Dialog.Button See [DialogButton]('./DialogButton/DialogButton.js') for details.
     */
    /**
     * Default dialog constructor
     *
     * @param {Dialog.Title} build.title
     * @param {Dialog.Description} build.description
     * @param {Dialog.Button[]} build.buttons
     * @param {Boolean} build.closeOutside If `false` disable dismiss dialog on touching outside.
     */
    constructor(build) {
        this.title = build.title;
        this.description = build.description;
        this.inputs = build.inputs;
        this.buttons = build.buttons;
        this.closeOutside = build.closeOutside;
        this.onCloseHandler = build.onCloseHandler;
    }

    static get Builder() {
        class Builder {
            constructor() {
                this.closeOutside = true;
            }

            /**
             * Disallow close dialog on click outside.
             *
             * @returns {Builder} itself
             */
            setOutsideNotClosable() {
                this.closeOutside = false;
                return this;
            }

            /**
             * @typedef {node} Dialog.Title See [DialogTitle]('./DialogTitle/DialogTitle.js') for details.
             */
            /**
             * Add description to dialog.
             * @param {Dialog.Title} title
             *
             * @returns {Builder} itself
             */
            withTitle(title) {
                this.title = title;
                return this;
            }

            /**
             * @typedef {node} Dialog.Description See [DialogDescription]('./DialogDescription/DialogDescription.js') for details.
             */
            /**
             * Add description to dialog.
             * @param {Dialog.Description} description
             *
             * @returns {Builder} itself
             */
            withDescription(description) {
                this.description = description;
                return this;
            }

            /**
             * @typedef {node} Dialog.Button See [DialogButton]('./DialogButton/DialogButton.js') for details.
             */
            /**
             * Append button to button collection.
             *
             * @param {Dialog.Button} button
             *
             * @returns {Builder} itself
             */
            appendButton(button) {
                if (!this.buttons) {
                    this.buttons = [];
                }
                this.buttons.push(button);
                return this;
            }

            withInputs(inputs) {
                this.inputs = inputs;
                return this;
            }

            /**
             * @typedef {node} Dialog.Button See [DialogButton]('./DialogButton/DialogButton.js') for details.
             */
            /**
             * Set buttons collection.
             *
             * @param {[Dialog.Button]} buttons
             *
             * @returns {Builder} itself
             */
            withButtons(buttons) {
                this.buttons = buttons;
                return this;
            }

            /**
             * Add handler on closing dialog
             *
             * @param {Function} onCloseHandler executes when calls dialog's `componentWillUnmount()`
             *
             * @returns {Builder} itself
             */
            onClose(onCloseHandler) {
                this.onCloseHandler = onCloseHandler;
                return this;
            }

            /**
             * Finish build and return AlertDialog instance
             *
             * @returns {AlertDialog}
             */
            build() {
                return new AlertDialog(this);
            }
        }
        return Builder;
    }

    /**
     * Shows alert dialog in modal way.
     *
     * *Note* It uses [<ReactPortal />]('https://ru.reactjs.org/docs/portals.html')
     *
     * @returns {React.RefObject} ref to modal dialog
     */
    show = () => {
        this.dismountFirst();
        const ref = React.createRef();

        this.dialog = (
            <Dialog.Container
                ref={ref}
                closeOutside={this.closeOutside}
                onClose={this.dismiss}
            >
                {this.title}
                {this.description}
                {this.inputs &&
                    this.inputs.map((e, i) =>
                        React.cloneElement(e, {
                            key: `dialog-input-${i}`
                        })
                    )
                }
                {this.buttons &&
                    this.buttons.map((b, i) =>
                        React.cloneElement(b, {
                            key: `dialog-button-${i}`
                        })
                    )}
            </Dialog.Container>
        );

        this.node = document.createElement('div');
        ReactDOM.render(this.dialog, this.node);

        return ref;
    };

    dismountFirst = () => {
        try {
            ReactDOM.unmountComponentAtNode(this.node);
        } catch {}
    };

    dismiss = () => {
        this.dismountFirst();
        this.onCloseHandler && this.onCloseHandler();
    };
}

export default AlertDialog;
